import {motion} from 'framer-motion' ; 
import { DatePicker, Icons, StringUtils, TableUtils } from 'tc-minibox';
import { SelectedDataItem } from '../Detail';
import React, { useEffect, useState } from 'react';
import MainButton from '../../../../tools/components/general/MainButton';
import { ServerProps } from '../../../../config/server.config';
import { useDispatch } from 'react-redux';
import { addRequestStatus, setLoaderStatus } from '../../../../store/global/actions.global';
import DropDown from '../../../../tools/components/general/DropDown';


export interface SummaryDetailsProps extends ServerProps {
   selected_data : SelectedDataItem,
   setSelectedData : React.Dispatch<React.SetStateAction<SelectedDataItem>>
}

export interface SummaryDetailsManager {
    edit : boolean, 
    initialize : boolean, 
}

export interface SummaryDetailsInfos {
    costestimate_date : Date, 
    contract_notification_date : Date,
    object : string,
    reference : string,
    manager : string, 
}

type EventsType = {
    hover : any
}


export default function SummaryDetails(props:SummaryDetailsProps) {
    const dispatch = useDispatch()
    const [manager, setManager] = useState<SummaryDetailsManager>({
        edit : false, 
        initialize : false
    })
    const [informations, setInformations] = useState<SummaryDetailsInfos>({
        costestimate_date : new Date(props.selected_data.costestimate_date), 
        contract_notification_date : new Date(props.selected_data.contract_notification_date),
        object : props.selected_data.object,
        reference : props.selected_data.reference,
        manager : props.selected_data.manager._id
    })
    const [direction, setDirection] = useState([])
    const direction_dropdown = direction.map((dr:any)=> {return {id : dr._id, name : dr._id, text : `${dr.firstname} ${dr.lastname}`}})

    const handleUpdateInformation = () => {
        props.server.post('/affairs/update-informations-affair', {id_affair : props.selected_data._id, content : {...informations, business_manager : informations.manager}})
        .then((res) => {
            props.setSelectedData((state) => {return {...state, ...informations}})
            setManager((state) => {return {...state, edit : false}})

            dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
        })
    }
    useEffect(() => {
        if(!manager.initialize) {
            props.server.get('/affairs/get-direction-user')
            .then(res => {
                setManager(state => {return{...state, initialization : "resolve" }})
                setDirection(res.data.data)
                //setAffairs(res.data.listing)
            })
            .catch(err => {
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }
    }, [])

    return (
        <motion.div 
            {...{
                initial : {x : -200, opacity : 0}, 
                animate : {x : 0, opacity : 1, transition : {duration : 0.3, delay : 0.2}}, 
                exit : {x : -200, opacity : 0}, 
            }}
            className="general-info"
            transition = {{
                duration : 0.4, 
                delay : 0.2
            }}
        >

            {
                !manager.edit ? 
                    <div 
                        className='edit-button'
                        onClick={() => setManager((state) => {return {...state, edit : true}})}
                    >
                        <Icons 
                            name = "pen" 
                            color = "white"
                        />
                    </div>
                : 
                        null
            }


            <div className='general-info__container'>
                <div>
                    <h1>Identification</h1>
                    <div>
                        <p><span>Affaire n°</span><span> {props.selected_data.affair_number}</span></p>
                        <p><span>Type </span>: <span>{props.selected_data.type}</span></p>
                    </div>
                </div>

                <div>
                    <h1>Informations</h1>
                    {
                        manager.edit ? 
                            <EditInformations
                                direction={direction_dropdown} 
                                informations={informations} 
                                setInformations={setInformations}
                                setManager={setManager}
                                selected_data={props.selected_data}
                                handleUpdate={handleUpdateInformation}
                            />
                        :
                            <div>
                                <p><span>Date du devis </span>: <span> {new Date(props.selected_data.costestimate_date).toLocaleDateString(('fr-FR'))}</span></p>
                                <p>
                                    <span>Date de notification du marché </span>: <span> {`${new Date(props.selected_data.contract_notification_date).toLocaleDateString(('fr-FR'))}`}</span>
                                </p>
                                <p><span>Objet </span>: <span> {props.selected_data.object}</span></p>
                                <p><span>Référence </span>: <span> {props.selected_data.reference}</span></p>
                                <p><span>Chargé d'affaires </span>: <span> {`${props.selected_data.manager.firstname} ${props.selected_data.manager.lastname}`}</span></p>
                            </div>
                    }
                </div>

                <div>
                    <h1>Totaux</h1>
                    <p><span>Total H.T </span>: <span>{TableUtils.formatNumberWithSpaces(props.selected_data.total_ht)}€</span></p>
                    <p><span>Total TVA </span>: <span>{TableUtils.formatNumberWithSpaces(props.selected_data.total_tva)}€</span></p>
                    <p><span>Total TTC </span>: <span>{TableUtils.formatNumberWithSpaces(props.selected_data.total_ttc)}€</span></p>
                </div>
            </div>
        </motion.div>
    )
}

interface EditInformationsProps {
    informations : SummaryDetailsInfos,
    setInformations : (infos : SummaryDetailsInfos) => void, 
    setManager : React.Dispatch<React.SetStateAction<SummaryDetailsManager>>,
    selected_data : any, 
    handleUpdate : () => void, 
    direction : any
}

function EditInformations(props:EditInformationsProps) {
    const [events, setEvents] = useState<EventsType>({
        hover : ''
    })

    return (
        <div className='edit-informations'>
            <div 
                className='close-button'
                onClick={() => props.setManager((state) => {return {...state, edit : false}})}
            >
                <Icons name = "xmark" color = "white"/>
            </div>
            <div>
                <label htmlFor='costestimate_date'>Date du devis</label>
                <DatePicker
                        selected={StringUtils.isUndefinedValue(props.informations.costestimate_date) ? new Date() : new Date(props.informations.costestimate_date)} 
                        dateFormat="dd/MM/yyyy"
                        onChange = {(e:any) => props.setInformations({...props.informations, costestimate_date : e})}
                        locale="fr"       
                    />
            </div>


            <div>
                <label htmlFor='contract_notification_date'>Date de notification du marché</label>
                <DatePicker
                    selected={StringUtils.isUndefinedValue(props.informations.contract_notification_date) ? new Date() : new Date(props.informations.contract_notification_date)} 
                    dateFormat="dd/MM/yyyy"
                    onChange = {(e:any) => props.setInformations({...props.informations, contract_notification_date : e})}
                    locale="fr"       
                />
            </div>

            <div>
                <label htmlFor='object'>Objet</label>
                <input 
                    type="text" 
                    value={props.informations.object} 
                    onChange = {(e:any) => props.setInformations({...props.informations, object : e.target.value})}
                />
            </div>
            <div>

            </div>

            <div>
                <label htmlFor='reference'>Référence</label>
                <input 
                    type="text" 
                    value={props.informations.reference} 
                    onChange = {(e:any) => props.setInformations({...props.informations, reference : e.target.value})}
                />
            </div>

            <div>
                <label htmlFor='business_manager'>Référence</label>
                <DropDown
                    reference = {"BUSINESS_MANAGER"}
                    hover = {events.hover}
                    setHover = {(e:any) => setEvents(state => {return{...state, hover : e}})}
                    value = {`${props.direction.find((el:any) => el.name === props.informations.manager)?.text}`}
                    setValue = {(e:any) => {
                        props.setInformations({...props.informations, manager : e})
                    }}
                    list = {props.direction}
                />
            </div>


            <MainButton
                handleClick={() => props.handleUpdate()}
            >
                Enregistrer
            </MainButton>



        </div>
    )
}