import React, { useEffect } from 'react' ;
import {motion} from 'framer-motion'
import { Icons, StringUtils } from 'tc-minibox';
import SearchSelect from '../../../../../../tools/components/general/SearchSelect';
import { ServerProps } from '../../../../../../config/server.config';
import EditInvAffairs from '../popups/EditInvAffairs';
import DropDown from '../../../../../../tools/components/general/DropDown';
import { types_invoice } from '../../../suppliersinv.utils';
import SearchSelect2 from '../../../../../../tools/components/general/SearchSelect2';
import { useDispatch } from 'react-redux';
import { addRequestStatus } from '../../../../../../store/global/actions.global';
import { text } from 'stream/consumers';

export interface UpdatingInvIdentificationProps extends ServerProps {
    selected_data : any,
    handleChangeInput : any,
    //setManager : any,
    handleSupposedSupplier? : any, 
    setData : (type:string, value:any) => void,
    rerender_supplier_search? : boolean,
}

interface UpdatingInvIdManager {
    edit_affairs : boolean,
}

interface UpdatingInvIdEvents {
    hover : string|null
}

type SuppliersType = {
    id : string, 
    text : string
}

export default function Identification(props:UpdatingInvIdentificationProps) {
    const dispatch = useDispatch()
    const [defaultSuppList, setDefaultSuppList] = React.useState<SuppliersType[]>([])
    const [manager, setManager] = React.useState<UpdatingInvIdManager>({
        edit_affairs : false,
    })

    const [events, setEvents] = React.useState<UpdatingInvIdEvents>({
        hover : null
    })

    useEffect(() => {
        props.server.post('/suppliers/initialize-select-supplier')
        .then(res => {
            setManager(state => { return {...state, initialize : true}})
            const data = res.data.content.map((dt:any) => {
                return {
                    id : dt._id,
                    text : dt.name,
                }
            })
            setDefaultSuppList(data)
            //dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            //handleChangeManager("initialization", "resolve")
            //dispatch(setLoaderStatus({status : false}))
        })
    }, [])

    return (
        <React.Fragment>
            {
                manager.edit_affairs ? 
                    <EditInvAffairs 
                        server={props.server}
                        handleClose = {() => setManager(state => {return{...state, edit_affairs : false}})}
                        selected_data = {props.selected_data}
                        setData = {() => console.log("a faire")}
                        handleChangeInput={props.handleChangeInput}
                    />
                : 
                    null
            }

            {/* TYPE */}
            <div className='type'>
                <label htmlFor='type'>Type</label>
                <DropDown
                    reference = {"AMENDMENTS-TYPE"}
                    hover = {events.hover}
                    setHover = {(e) => setEvents(state => {return{...state, hover : e}})}
                    value = {props.selected_data.type}
                    setValue = {(e) => {
                        //props.handleChangeInput({target : {name : "type", value : e.name}})
                        props.setData("type", e)

                        /*props.setData((state:any) => {
                            // NEGATIVE IF TYPE CREDIT NOTE
                            return state.map((st:any) => {
                                if(st.url === props.selected_data.url) {
                                    if(st.id_affairs.length > 1) {
                                        dispatch(addRequestStatus({
                                            status : true, 
                                            message : "Suite à cette modification, l'édition des affaires a été réinitialisée.", 
                                        }))
                                    }
                                    return {
                                        ...st, 
                                        type : e.name, 
                                        total_tva : e.name === "credit-note" ? Math.abs(st.total_tva) * -1 : Math.abs(st.total_tva), 
                                        total_ttc : e.name === "credit-note" ? Math.abs(st.total_ttc) * -1 : Math.abs(st.total_ttc), 
                                        id_affairs : st.id_affairs.slice(0,1).map((aff:any) => {
                                            return {
                                                ...aff, 
                                                ht : e.name === "credit-note" ? Math.abs(aff.ht) * -1 : Math.abs(aff.ht),
                                                tva : e.name === "credit-note" ? Math.abs(aff.tva) * -1 : Math.abs(aff.tva),
                                            }
                                        })
                                    }
                                }
                                return st
                            })
                            /*return {
                                ...state, 
                                type : e.name, 
                                total_tva : e.name === "credit-note" ? Math.abs(state.total_tva) * -1 : Math.abs(state.total_tva), 
                                total_ttc : e.name === "credit-note" ? Math.abs(state.total_ttc) * -1 : Math.abs(state.total_ttc), 
                                id_affairs : state.id_affairs.map((aff:any) => {
                                    return {
                                        ...aff, 
                                        ht : e.name === "credit-note" ? Math.abs(aff.ht) * -1 : Math.abs(aff.ht),
                                        tva : e.name === "credit-note" ? Math.abs(aff.tva) * -1 : Math.abs(aff.tva),
                                    }
                                })
                            }
                        })*/
                    }}
                    list = {types_invoice}
                />
            </div>

            {/*INVOICE NUMBER */}
            <div className='doc_number'>
                <label htmlFor='doc_number'>Numéro de pièce comptable</label>
                <input 
                    type = "text"
                    name = "doc_number"
                    value = {props.selected_data.doc_number}
                    onChange={props.handleChangeInput}
                />
            </div>

            {/*INVOICE NUMBER */}
            <div className='inv_number'>
                <label htmlFor='inv_number'>Numéro de facture</label>
                <input 
                    type = "text"
                    name = "inv_number"
                    value = {props.selected_data.inv_number}
                    onChange={props.handleChangeInput}
                />
                {StringUtils.isUndefinedValue(props.selected_data.inv_number) || props.selected_data.inv_number === "" ? 
                    <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
                : 
                    null
                }
            </div>


            {/*ID_SUPPLIER */}
            <div className='id_supplier'>
                <label htmlFor='id_supplier'>Fournisseur</label>
                {
                    props.rerender_supplier_search?? true ? 
                    <SearchSelect2 
                    adding = {true}
                    value = {{
                        id : props.selected_data.id_supplier._id,
                        text : props.selected_data.id_supplier.name
                    }}
                    server = {{
                        axios : props.server,
                        route : "/invoicing/search-suppliers"
                    }}
                    handleAdd = {(e:string) => {
                        props.handleSupposedSupplier(e)
                    }}
                    no_data_text = {"Aucune affaire trouvée"}
                    defaultList = {defaultSuppList as any}
                    handleClick = {(data) => {
                        props.handleChangeInput({target : {name : "id_supplier", value : {
                            _id : data.id, 
                            name : data.text
                        }}})
                    }}
                    />
                    : 
                        null
                }

                {
                    /*
                                    <SearchSelect
                    {...{
                        adding : true, 
                        handleAdd : (e:string) => {
                            props.handleSupposedSupplier(e)
                        },
                        text_no_data : "Aucune affaire trouvée", 
                        handleClick : (data) => {
                            props.handleChangeInput({target : {name : "id_supplier", value : data.id}})
                            props.handleChangeInput({target : {name : "name_supplier", value : data.text}})
                        },
                        handleDelete : () => props.handleChangeInput({target : {name : "id_supplier", value : null}}),
                        no_data_text : "Aucune affaire trouvée", 
                        server : {
                            axios : props.server,
                            route : "/invoicing/search-suppliers"
                        },
                        value : props.selected_data.id_supplier,
                    }}
                    defaultList = {[
                        { id: 1, text: "Option 1", description: "This is option 1" },
                        { id: 2, text: "Option 2", description: "This is option 2" },
                        { id: 3, text: "Option 3", description: "This is option 3" },
                        { id: 4, text: "Option 4", description: "This is option 4" },
                        { id: 5, text: "Option 5", description: "This is option 5" },
                        { id: 6, text: "Option 6", description: "This is option 6" },
                        { id: 7, text: "Option 7", description: "This is option 7" },
                        { id: 8, text: "Option 8", description: "This is option 8" },
                        { id: 9, text: "Option 9", description: "This is option 9" },
                        { id: 10, text: "Option 10", description: "This is option 10" }
                    ]}
                />
                    */
                }

                {StringUtils.isUndefinedValue(props.selected_data.id_supplier._id) || props.selected_data.id_supplier._id === "" ? 
                    <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
                : 
                    null
                }

            </div>
            {
                props.handleSupposedSupplier && props.selected_data.id_supplier._id === "" ? 
                    <p className = "id_supplier--supposed-supplier">
                        <span>Fournisseur supposé :</span>
                        <span onClick = {() => props.handleSupposedSupplier(props.selected_data.supposed_supplier)}>{`${props.selected_data.supposed_supplier}`}</span>
                    </p>
                : 
                    null
            }

            <div className='more_affairs'>
                <motion.button
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.97 }}
                    onClick = {() => setManager(state => {return{...state, edit_affairs : true}})}
                >
                    Édition de la répartition des affaires
                </motion.button>
            </div>
            
        </React.Fragment>
    )
}